import $ from "jquery";

declare var dataLayer: any[];

export default class ProductList {
  static init() {
    ProductList.initFilter();
    ProductList.initMobileFilter();
    ProductList.initPager();

    ProductList.trackNoSearchResults();
  }

  private static initFilter() {
    const $productList = $(".nt-product-list");

    // show "clear filters" link when filters are selected
    $productList
      .find(".js-product-filter--clear")
      .toggleClass("d-none", this.determineHasFilters(".js-product-filter--dropdown") === false);

    // event handlers
    $productList.on("click", ".js-product-filter--clear", (e: JQueryEventObject) => {
      e.preventDefault();
      ProductList.handleFilterClear(".js-product-filter--form", ".js-product-filter--dropdown");
    });

    $productList.on("change", ".js-product-filter--dropdown", (e: JQueryEventObject) => {
      const $dropdown = $(e.currentTarget) as JQuery<HTMLElement>;
      ProductList.handleFilterChange(".js-product-filter--form", $dropdown);
      ProductList.submitSearch(".js-product-filter--form", null);
    });
  }

  private static initMobileFilter() {
    const $productList = $(".nt-product-list");

    // show "clear filters" link when filters are selected
    $productList
      .find(".js-mobile-product-filter--clear")
      .toggleClass("d-none", this.determineHasFilters(".js-mobile-product-filter--dropdown") === false);

    // event handlers
    $productList.on("click", ".js-mobile-product-filter--open", (e: JQueryEventObject) => {
      e.preventDefault();
      ProductList.toggleMobileFilter(true);
    });

    $productList.on("click", ".js-mobile-product-filter--close", (e: JQueryEventObject) => {
      e.preventDefault();
      ProductList.toggleMobileFilter(false);
    });

    $productList.on("click", ".js-mobile-product-filter--clear", (e: JQueryEventObject) => {
      e.preventDefault();
      ProductList.handleFilterClear(".js-mobile-product-filter--form", ".js-mobile-product-filter--dropdown");
    });

    $productList.on("click", ".js-mobile-product-filter--submit", (e: JQueryEventObject) => {
      e.preventDefault();
      ProductList.submitSearch(".js-mobile-product-filter--form", null);
    });

    $productList.on("change", ".js-mobile-product-filter--dropdown", (e: JQueryEventObject) => {
      const $dropdown = $(e.currentTarget) as JQuery<HTMLElement>;
      ProductList.handleFilterChange(".js-mobile-product-filter--form", $dropdown);
    });
  }

  private static initPager() {
    const $productList = $(".nt-product-list");

    $productList.on("click", ".js-change-page", (e) => {
      e.preventDefault();

      const newIx = $(e.currentTarget).data("ix");

      const isMobileForm = $(".js-product-filter--form").is(":visible") === false;
      const form = isMobileForm ? ".js-mobile-product-filter--form" : ".js-product-filter--form";
      $productList.find(form).find(".js-page-index").val(newIx);

      ProductList.submitSearch(form, null);
    });
  }

  private static trackNoSearchResults() {
    const $noSearchResults = $(".nt-product-list").find(".js-product-search--no-results");

    if ($noSearchResults.length === 0) {
      return;
    }

    const searchTerm = $noSearchResults.data("search-term");
    dataLayer.push({ event: "search-no-results", search_keyword: searchTerm });
  }

  private static determineHasFilters(dropdown: string) {
    const $productList = $(".nt-product-list");

    let hasFilters = false;

    $productList.find(dropdown).each(function () {
      var val = $(this).val();
      if (val != "0" && val != "" && val != null) {
        hasFilters = true;
      }
    });

    return hasFilters;
  }

  private static submitSearch(form: string, arg: any) {
    const $productList = $(".nt-product-list");

    if (arg != null) {
      if (typeof arg === "string") {
        $productList.find(form).find(".js-search-term").val(arg);
      } else {
        if (arg.value != null) {
          $productList.find(form).find(".js-search-term").val(arg.value);
        }
        if (arg.data != null && arg.data.url != null) {
          document.location.href = arg.data.url;
          return;
        }
      }
    }

    $productList.find(form).trigger("submit");
  }

  private static handleFilterChange(form: string, $dropdown: JQuery<HTMLElement>) {
    const $productList = $(".nt-product-list");

    const ix = $dropdown.data("ix");
    const value = $dropdown.val() as string;

    const isBrandFilter = $dropdown.hasClass("js-product-filter--is-brand");
    if (isBrandFilter) {
      $(".js-product-filter--brand-id").val(value);
    }

    const isCollectionFilter = $dropdown.hasClass("js-product-filter--is-collection");
    if (isCollectionFilter) {
      $(".js-product-filter--collection-id").val(value);
    }

    $productList.find(form).find(`.js-product-filter--prop-value-${ix}`).val(value);
  }

  private static handleFilterClear(form: string, dropdown: string) {
    document.location.href = $(".nt-product-list").data("reset-url");
  }

  private static toggleMobileFilter(show: boolean) {
    const $productList = $(".nt-product-list");

    $productList.find(".js-mobile-product-filter").toggleClass("d-none", !show);

    if (show) {
      $("body").css("overflow-y", "hidden"); // hide vertical scrollbar
    } else {
      $("body").css("overflow-y", "auto"); // show vertical scrollbar
    }
  }
}
